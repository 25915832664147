<template>
  <div>
    <b-overlay
      :show="overlay_partner"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card
        title="Data Member"
        no-body
        :aria-hidden="overlay_partner ? true : null"
      >
        <b-card-body>
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-2"
              @click="exportPartner"
            >
              Download Excel
            </b-button>
          </div>

          <div class="d-flex justify-content-end flex-wrap">
            <b-form-group
              label="Filter Date"
              label-size="sm"
              label-for="filterDate"
              class="mb-0 ml-2"
            >
              <b-input-group size="sm">
                <flat-pickr
                  id="filterDate"
                  v-model="filter_date"
                  class="form-control"
                  :config="{ mode: 'range'}"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Sort"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-md-0 ml-2"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    ASC
                  </option>
                  <option :value="true">
                    DESC
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Filter"
              label-size="sm"
              label-for="filterBy"
              class="mb-0 ml-2"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="filterBy"
                  v-model="partner"
                  :options="options_partner"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Search"
              label-size="sm"
              label-for="filterInput"
              class="mb-0 ml-2"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="itemsFiltered"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(name)="data">
            <div class="d-flex flex-row">
              <b-avatar
                v-if="data.item.logo !== null"
                class="mr-50"
                :src="data.item.image"
              />
              <b-avatar
                v-else
                class="mr-50"
                variant="primary"
                :text="data.item.name | initial"
              />
              <div class="d-flex flex-column">
                <p class="m-0">
                  {{ data.item.name }}
                </p>
                <small class="text-muted">
                  {{ data.item.email }}
                </small>
              </div>
            </div>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardBody,
  BTable, BAvatar,
  BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
  BOverlay,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardBody,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'name', label: 'User Name', sortable: true },
        { key: 'id_number', label: 'Member ID', sortable: true },
        { key: 'partner', label: 'Partner', sortable: true },
        { key: 'periode_text', label: 'Tanggal', sortable: true },
      ],
      items: [],
      overlay_partner: true,
      active_role: null,

      options_partner: [
        { value: 'all', text: 'All Partner' },
      ],
      partner: 'all',
      filter_date: null,
      start_date: null,
      end_date: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    itemsFiltered() {
      let { items } = this

      if (this.filter_date) {
        const date = this.filter_date.split(' to ')
        this.start_date = this.formatDate(new Date(date[0]))
        this.end_date = null

        if (date[1] !== undefined) {
          this.end_date = this.formatDate(new Date(date[1]))
        }

        items = this.items.filter(item => {
          const dateItem = this.formatDate(new Date(item.periode))

          if (date[1] !== undefined) {
            return (dateItem >= this.start_date && dateItem <= this.end_date)
          }
          return dateItem.getTime() === this.start_date.getTime()
        })
      }

      if (this.partner !== 'all') {
        items = items.filter(item => item.id_partner === this.partner)
      }

      this.totalRows = items.length

      return items
    },
  },
  created() {
    this.active_role = localStorage.getItem('role')

    this.checkAuth()

    this.getPartnerMember()
    this.getPartnerAll()
  },
  methods: {
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = this.itemsFiltered
      this.currentPage = 1
    },
    async getPartnerMember() {
      const cid = localStorage.getItem('client_id')
      let dataAPI = null
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
      dataAPI = await this.$axios.get(`${cid}/partner/member`, config)

      this.items = dataAPI.data.data

      // Set the initial number of items
      this.totalRows = this.items.length

      this.overlay_partner = false
    },
    async getPartnerAll() {
      const cid = localStorage.getItem('client_id')

      let dataAPI = null
      if (this.active_role === 'corporate') {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
        dataAPI = await this.$axios.get(`${cid}/partner`, config)
      }

      // define partner data
      const partnerTemp = []
      dataAPI.data.data.parent.forEach(item => {
        let textPartner = ''
        if (item.name.length > 25) {
          textPartner = `${item.name.substring(0, 25)}...`
        } else {
          textPartner = item.name
        }

        const partner = {
          value: item.id,
          text: textPartner,
        }

        partnerTemp.push(partner)
      })

      // sort partner data
      partnerTemp.sort((a, b) => {
        const textA = a.text.toUpperCase()
        const textB = b.text.toUpperCase()

        if (textA < textB) {
          return -1
        }

        return 1
      })

      // merge 2 data
      this.options_partner = this.options_partner.concat(partnerTemp)
    },
    exportPartner() {
      const cid = localStorage.getItem('client_id')
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }

      let startDate = null
      let endDate = null
      if (this.start_date) {
        startDate = `${this.formatDateText(this.start_date)}`
      }

      if (this.end_date) {
        endDate = `${this.formatDateText(this.end_date)}`
      }

      const send = {
        filter_name: this.filter,
        partner: this.partner,
        sort_by: (this.sortBy === '') ? null : this.sortBy,
        sort_type: (this.sortDesc) ? 'desc' : 'asc',
        startDate,
        endDate,
      }

      this.$axios.post(`${cid}/partner/export`, send, config)
        .then(res => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/xlsx' }))
          const fURL = document.createElement('a')

          fURL.href = fileURL
          fURL.setAttribute('download', 'file.xlsx')
          document.body.appendChild(fURL)

          fURL.click()
        })
        .catch(error => {
          console.log(error)
        })
    },
    formatDate(date) {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    },
    formatDateText(dateFull) {
      const year = dateFull.getUTCFullYear()
      let month = dateFull.getMonth() + 1
      let date = dateFull.getDate()

      month = month.toString()
      month = month.padStart(2, '0')

      date = date.toString()
      date = date.padStart(2, '0')

      return `${year}-${month}-${date}`
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
