<template>
  <div>
    <b-row>
      <b-col
        v-if="active_role == 'corporate'"
        col="12"
      >
        <tracking-corporate />
      </b-col>
      <b-col
        v-else-if="active_role == 'superadmin'"
        col="12"
      >
        <tracking-superadmin />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import TrackingCorporate from './tracking/TrackingCorporate.vue'
import TrackingSuperadmin from './tracking/TrackingSuperadmin.vue'

export default {
  components: {
    BRow,
    BCol,
    TrackingCorporate,
    TrackingSuperadmin,
  },
  data() {
    return {
      active_role: null,
    }
  },
  created() {
    this.active_role = localStorage.getItem('role')
    this.checkAuth()
  },
}
</script>
